import alertify from "alertifyjs";
const setmore_services = require("./setmore_services.json");
import { SET_MORE_BASE_URL } from "./setmore_services_const";

window.alertify = alertify;

alertify.API_Search ||
  alertify.dialog("API_Search", function () {
    var iframe;
    return {
      set: function () {
        return {};
      },
      main: function (API_Search_URL) {
        return this.set({ API_Search_URL: API_Search_URL, title: "" });
      },
      setup: function () {
        return {
          options: {
            padding: false,
            overflow: false,
            resizeTo: true,
          },
        };
      },
      build: function () {
        iframe = document.createElement("iframe");
        iframe.frameBorder = "no";
        iframe.width = "100%";
        iframe.allowtransparency = "true";
        iframe.height = "100%";
        iframe.id = "popupIframe";
        this.elements.content.appendChild(iframe);
        this.resizeTo("100%", "100%");
        $(".ajs-maximize").hide();
      },
      settings: { API_Search_URL: undefined },
      settingUpdated: function (key, oldValue, API_Search_URL) {
        $(".ajs-dialog").css("background", "none transparent");
        $(".ajs-header").css("background", "none transparent");
        $(".ajs-header").css("visibility", "collapse");
        $(".ajs-footer").css("background", "none transparent");
        $(".ajs-body").css("background", "none transparent");
        $(".ajs-close").addClass("close_button_popup");
        $(".ajs-maximize").hide();
        if (key === "API_Search_URL") {
          iframe.src = API_Search_URL;
        }
      },
      hooks: {
        onclose: function () {
          iframe.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
        },
        onupdate: function (option, oldValue, newValue) {
          $(".ajs-maximize").hide();
          if (option === "resizable") {
            if (newValue) {
              this.elements.content.removeAttribute("style");
              iframe && iframe.removeAttribute("style");
            } else {
              this.elements.content.style.minHeight = "inherit";
              iframe && (iframe.style.minHeight = "inherit");
            }
          }
        },
      },
    };
  });

$(document).ready(function () {
  $(".popup").click(function (event) {
    event.preventDefault();
    popup($(this).attr("href"));
    return false;
  });

  // Disabling to show errors to team
  $("img").on("error", function () {
    $(this)
      .unbind("error")
      .attr("src", location.origin + "/images/logo.png");
  });
});

function getQueryParams(url) {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const queryParams = {};
  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
}

function countMatchingCharacters(str1, str2) {
  let count = 0;
  for (let i = 0; i < str1.length; i++) {
    if (str2.includes(str1[i])) {
      count++;
    }
  }
  return count;
}

// Normalize strings by removing special characters, spaces and converting to lower case
function normalizeString(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
}

window.popup = function (url) {
  var string_http_check = url.indexOf("http") !== -1;

  if (!string_http_check) {
    url = location.origin + "/" + url;
  }

  // Parse query parameters from the provided URL
  const queryParams = getQueryParams(url);
  const autoSelectServiceName = queryParams?.auto_select_service_name;

  // Log the service name if it exists in the query parameters
  if (autoSelectServiceName) {
    const normalizedServiceName = normalizeString(autoSelectServiceName);
    console.log("Auto-selected service name:", autoSelectServiceName);
    // Find the best matching service from the setmoreServices array
    const bestMatchService =
      setmore_services.find((service) => {
        console.log({ service });
        const normalizedTitle = normalizeString(service.title);
        return normalizedServiceName.includes(normalizedTitle);
      }) || null;

    console.log({ bestMatchService });
    // If a best match is found, log it or perform further actions
    if (bestMatchService) {
      console.log("Best matching service:", bestMatchService);

      // Optionally, update the URL with service parameters
      // we will turn on this after unit testing and also this is 2nd option
      // url += "&products=" + bestMatchService.params.products;
    } else {
      console.log(
        "No matching service found in setmore_services.json for ",
        autoSelectServiceName
      );
    }
  }

  alertify.API_Search(url).set({ frameless: true }).maximize();
};

window.convertToSlug = function (Text) {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

window.getCookie = function (cookieName) {
  let cookie = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};
